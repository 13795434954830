import type { User } from "@prisma/client";
import { MarketplaceSide } from "@prisma/client";
import type { SerializeFrom } from "@remix-run/node";
import { Form, Link, useLocation } from "@remix-run/react";

import { DownOutlined, HomeOutlined, PlusOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Button, Layout, Menu } from "antd";

import UserIcon from "~/components/user";

const { Header } = Layout;

function UserDropdown({ user }: { user: SerializeFrom<User> }) {
  return (
    <div className="Header-userDropdown">
      <UserIcon user={user} />
      <DownOutlined style={{ marginLeft: "12px" }} />
    </div>
  );
}

export default function AppHeader({
  user,
}: {
  user?: SerializeFrom<User> | null;
}) {
  const location = useLocation();

  let selectedKey: string | undefined;
  if (location.pathname.includes("home")) {
    selectedKey = "home";
  } else if (location.pathname.includes("browse")) {
    selectedKey = "browse";
  } else if (location.pathname.includes("listings/new")) {
    selectedKey = "newListing";
  }

  let centerHeaderItems: MenuProps["items"] = [];
  let rightHeaderItems: MenuProps["items"] = [];

  if (user) {
    const marketplaceSide = user.marketplaceSide;
    centerHeaderItems = [
      {
        // Empty item to put header-2 and header-3 in center
        key: "header-0",
        label: "",
        style: {
          marginRight: "auto",
        },
      },
      {
        key: "home",
        icon: <HomeOutlined />,
        label: <Link to="/">Home</Link>,
      },
      {
        // Empty item to make line between icons
        key: "line",
        label: "",
        style: {
          margin: "8px 0",
          padding: 0,
          borderRight: "1px solid var(--neutral-5)",
        },
      },
    ];

    if (marketplaceSide === MarketplaceSide.BUY) {
      centerHeaderItems.push({
        key: "browse",
        label: <Link to="/browse">Browse Tax Credits</Link>,
      });
    } else if (marketplaceSide === MarketplaceSide.SELL) {
      centerHeaderItems.push({
        key: "newListing",
        label: (
          <a href="/listings/new">
            <PlusOutlined /> New Listing{" "}
          </a>
        ),
      });
    }

    rightHeaderItems.push({
      key: "header-4",
      label: <UserDropdown user={user} />,
      children: [
        {
          type: "group",
          key: "logout",
          label: (
            <Form action="/logout" method="post">
              <Button htmlType="submit" type="text" block>
                Logout
              </Button>
            </Form>
          ),
        },
      ],
    });
  }

  // Move the right header items all the way to the right
  if (rightHeaderItems[0]) {
    rightHeaderItems[0].style = {
      marginLeft: "auto",
    };
  }

  const items = centerHeaderItems.concat(rightHeaderItems);

  return (
    <Header className="Header">
      <Logo shouldLinkToHome={user !== null} />
      <Menu
        className="Header-menu"
        mode="horizontal"
        selectedKeys={selectedKey ? [selectedKey] : []}
        items={items}
      />
    </Header>
  );
}

/* Helpers */

function Logo({ shouldLinkToHome }: { shouldLinkToHome: boolean }) {
  return shouldLinkToHome ? (
    <Link to="/" className="Header-logoLink">
      <div className="Header-logo" />
    </Link>
  ) : (
    <div className="Header-logo" />
  );
}
