import { WarningOutlined } from "@ant-design/icons";
import type {
  LinksFunction,
  LoaderArgs,
  MetaFunction,
  Session,
} from "@remix-run/node";
import { json, redirect } from "@remix-run/node";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
} from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import { withSentry } from "@sentry/remix";
import { Card, Layout, message } from "antd";
import * as React from "react";

import Footer from "~/components/footer";
import Header from "~/components/header";
import * as gtag from "~/utils/gtags.client";
import {
  MESSAGE_KEY_ERROR,
  MESSAGE_KEY_SUCCESS,
} from "~/utils/permissions.server";
import {
  commitSession,
  getSession,
  getVerifiedCredentialedUser,
} from "~/utils/session.server";
import { getEventsToTrack } from "./utils/gtags.server";

import normalize from "normalize.css/normalize.css";
import antdStyleSheet from "./styles/antd.css";
import globalStyleSheet from "./styles/styles.css";

export const links: LinksFunction = () => {
  return [
    { rel: "stylesheet", href: normalize },
    { rel: "stylesheet", href: antdStyleSheet },
    { rel: "stylesheet", href: globalStyleSheet },
    { rel: "icon", type: "image/x-icon", href: "/images/favicon.ico" },
  ];
};

export const meta: MetaFunction = () => ({
  charset: "utf-8",
  title: "Atheva",
  viewport: "width=device-width,initial-scale=1",
});

export async function loader({ request }: LoaderArgs) {
  upgradeToHttps(request);

  const session = await getSession(request);
  return json(
    {
      ENV: {
        GOOGLE_MAPS_API_KEY: process.env.GOOGLE_MAPS_API_KEY,
        HEROKU_SLUG_COMMIT: process.env.HEROKU_SLUG_COMMIT,
        SENTRY_DSN: process.env.SENTRY_DSN,
        SENTRY_ENVIRONMENT: process.env.ENVIRONMENT || process.env.NODE_ENV,
        STRIPE_PUBLIC_KEY: process.env.STRIPE_PUBLIC_KEY,
        TEST: process.env.TEST,
      },
      gaEvents: getEventsToTrack(session),
      gaTrackingId: process.env.GA_TRACKING_ID,
      messages: getMessages(session),
      user: await getVerifiedCredentialedUser(request),
    },
    {
      headers: {
        "Set-Cookie": await commitSession(session),
      },
    }
  );
}

function App() {
  const { hash, key, pathname } = useLocation();
  const data = useLoaderData<typeof loader>();
  const { ENV, gaEvents, gaTrackingId, messages } = data;

  React.useEffect(() => {
    // this is going to display twice when in DEV because
    // of React strictmode:
    // https://stackoverflow.com/questions/61254372/my-react-component-is-rendering-twice-because-of-strict-mode
    messages.success.forEach((msg) => message.success(msg));
    messages.failure.forEach((msg) => message.error(msg));
  }, [messages]);

  React.useEffect(() => {
    if (gaTrackingId) {
      gtag.pageview(pathname, gaTrackingId);
      for (const gaEvent of gaEvents) {
        const { action, category, label, value } = gaEvent;
        gtag.event({ action, category, label, value });
      }
    }
  }, [gaEvents, gaTrackingId, pathname]);

  // Scroll to #anchor
  React.useEffect(() => {
    if (hash !== "") {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) element.scrollIntoView();
      }, 0);
    }
  }, [hash, key, pathname]); // Trigger on route change

  return (
    <html lang="en">
      <head>
        <Meta />
        <Links />
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300italic,400italic,500,500italic,700,700italic,900italic,900"
          rel="stylesheet"
          type="text/css"
        ></link>
        <GATag trackingId={gaTrackingId} />
      </head>
      <body>
        <Layout style={{ height: "100%" }}>
          <Header user={data.user} />
          <Layout className="ContentContainer">
            <Outlet />
          </Layout>
          <Footer />
        </Layout>
        <ScrollRestoration />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(ENV)}`,
          }}
        />
        <Scripts />
        <LiveReload />
      </body>
    </html>
  );
}

export function ErrorBoundary({ error }: { error: Error }) {
  console.error(error);
  Sentry.captureException(error);
  return (
    <html>
      <head>
        <title>Oh no!</title>
        <Meta />
        <Links />
      </head>
      <body>
        <Layout style={{ height: "100%" }}>
          <Header />
          <Layout className="ContentContainer">
            <Card>
              <h1>
                <WarningOutlined /> An error occurred - please try again.
              </h1>
            </Card>
          </Layout>
          <Footer />
        </Layout>
        <Scripts />
      </body>
    </html>
  );
}

// FIXME: CatchBoundary appears to be bugged
// https://github.com/remix-run/remix/discussions/5055
// Even if it worked, we would not be able to pull response headers,
// so we would not be able to display RequestID
// export function CatchBoundary() {
//   const caught = useCatch();
//
//   return (
//     <div>
//       <h1>Caught</h1>
//       <p>Status: {caught.status}</p>
//       <pre>
//         <code>{JSON.stringify(caught.data, null, 2)}</code>
//       </pre>
//     </div>
//   );
// }

/**
 * Forward http requests to https
 * Based on https://github.com/remix-run/remix/discussions/2915#discussioncomment-3521589
 * This workaround won't be necessary once Remix releases planned middleware updates
 */
function upgradeToHttps(request: Request) {
  let url = new URL(request.url);
  let hostname = url.hostname;
  let proto = request.headers.get("X-Forwarded-Proto") ?? url.protocol;

  url.host =
    request.headers.get("X-Forwarded-Host") ??
    request.headers.get("host") ??
    url.host;
  url.protocol = "https:";

  if (proto === "http" && hostname !== "localhost") {
    throw redirect(url.toString(), {
      headers: {
        "X-Forwarded-Proto": "https",
      },
    });
  }
}

export default withSentry(App, {
  wrapWithErrorBoundary: false,
});

function getMessages(session: Session) {
  const messages: { success: (string | null)[]; failure: (string | null)[] } = {
    success: [],
    failure: [],
  };

  const successMessage = session.get(MESSAGE_KEY_SUCCESS);
  if (successMessage) messages.success.push(successMessage);

  const errorMessage = session.get(MESSAGE_KEY_ERROR);
  if (errorMessage) messages.failure.push(errorMessage);

  return messages;
}

function GATag({ trackingId }: { trackingId: string | undefined }) {
  if (!trackingId) return null;

  return (
    <>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${trackingId}`}
      ></script>
      <script
        async
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${trackingId}', {
            page_path: window.location.pathname,
          });
        `,
        }}
      ></script>
    </>
  );
}
