import { Layout, Space } from "antd";

const { Footer } = Layout;

export default function AppHeader() {
  return (
    <Footer className="ContentContainer Footer">
      <Space size={50}>
        <a href="https://atheva.com/privacy" rel="noreferrer" target="_blank">
          Privacy policy
        </a>
        <a
          href="https://www.atheva.com/terms-of-use"
          rel="noreferrer"
          target="_blank"
        >
          Terms of Use
        </a>
        <a
          href="https://www.atheva.com/contact-us"
          rel="noreferrer"
          target="_blank"
        >
          Contact us
        </a>
        <a href="https://www.atheva.com/blog" rel="noreferrer" target="_blank">
          Blog
        </a>
      </Space>
      <div style={{ color: "var(--neutral-8)" }}>© 2024 Atheva, Inc.</div>
    </Footer>
  );
}
